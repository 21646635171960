/**
 * Modal Form: Group
 */
.modal--container .modal--form .modal--form-group {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.modal--container .modal--form .modal--form-group label {
  font-family: 'Century Gothic', 'Lucida Sans Unicode', sans-serif, Verdana, Arial;
  font-size: 14px;
  margin-bottom: 10px;
  color: #333;
  text-align: left;
}

.modal--container .modal--form .modal--form-group input {
  border-radius: 3px;
  outline: none;
  box-shadow: none;
  border: 1px solid #dfdfdf;
  padding: 10px;
  font-size: 16px;
}

.modal--container .modal--form .modal--form-group input::placeholder {
  color: #ccc;
}

.modal--container .modal--form .modal--form-label-helper {
  font-size: .75rem;
  color: #999;
  margin-bottom: .5rem;
  line-height: 1rem;
  text-align: left;
}
