/**
 * Modal Content
 */
.modal--container .modal--content {
  padding: 30px 40px;
  display: flex;
  justify-content: space-between;
}

/**
 * Modal Content: Left
 */
.modal--container .modal--content .modal--content-left {
  width: 30%;
  position: relative;
}

/**
 * Modal Content: Right
 */
.modal--container .modal--content .modal--content-right {
  width: 65%;
  margin-left: 5%;
}

/**
 * Modal Content: Image Adjust
 */
.modal--container .modal--content .modal--content-left img,
.modal--container .modal--content .modal--content-right img {
  max-width: 100%;
}
