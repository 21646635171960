.editor--content > div > div {
  position: initial !important;
}


.image-node--container {
  display: inline-block;
  position: relative;
}


.image-node {
  max-width: 100%;
  position: relative;
}

.image-node.selected {
  border: 3px dotted blue;
}
