/**
 * Tooltip
 */
.tooltip--container {
  display: none;
  position: absolute;
  left: 0;
  bottom: -55px;
  font-family: 'Trebuchet MS', 'Helvetica Neue', Helvetica, Tahoma, sans-serif;
  background-color: rgba(0,0,0,.95);
  color: #555;
  font-size: 14px;
  padding: 16px;
  border-radius: 3px;
  min-width: 275px;
  max-height: 290px;
  text-align: left;
  user-select: none;
  z-index: 1;
}

/**
 * Tooltip Item
 */
.tooltip--container .tooltip--item {
  display: inline-block;
  font-size: 14px;
  color: #ccc;
  text-decoration: none;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  border-left:  1px solid #555;
  padding:  0 10px;
  cursor: pointer;
}
.tooltip--container .tooltip--item:first-of-type {
  border: none;
}
.tooltip--container .tooltip--item:hover,
.tooltip--container .tooltip--item:active,
.tooltip--container .tooltip--item:focus {
  color: #fff;
}

/**
 * Tooltip Item: Link Adjusts
 */
.tooltip--container .tooltip--item > a,
.tooltip--container .tooltip--item > a:visited {
  text-decoration: none;
  color: #ccc;
}

.tooltip--container .tooltip--item > a:hover,
.tooltip--container .tooltip--item > a:active,
.tooltip--container .tooltip--item > a:focus {
  color: #fff;
}
