.slate-color-plugin--draggable-handle-container {
  position: absolute;
  z-index: 9;
  box-sizing: border-box;
}

.slate-color-plugin--draggable-handle {
  content: '';
  width: 100px;
  height: 7px;
  position: absolute;
  left: 50%;
  top: 1px;
  z-index: 1;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  margin-left: -50px;
  cursor: all-scroll;
  box-sizing: border-box;
}
.slate-color-plugin--draggable-handle:after {
  content: '';
  height: 3px;
  width: 100%;
  position: absolute;
  top: 1px;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  box-sizing: border-box;
}
